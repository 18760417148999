import { render, staticRenderFns } from "./authorization.vue?vue&type=template&id=040d4c9c&scoped=true&"
import script from "./authorization.vue?vue&type=script&lang=js&"
export * from "./authorization.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040d4c9c",
  null
  
)

export default component.exports