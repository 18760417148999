<template>
    <div>

    </div>
</template>

<script>
export default {
    name: "authorization",
    data() {
        return {
            auth_code: '',
        }
    },
    created() {
        var open_uri = this.getUrlParam('open_url')
        if (open_uri){
            this.get_auto_code_url()
        }
        var auth_code = this.getUrlParam('auth_code')
        //获取微信第三方授权码
        if (auth_code) {
            this.auth_code = auth_code
            this.get_autoinfo()
        }
    },
    methods: {
        //获取微信第三方授权页面
        get_auto_code_url(){
            this.requestApi('/adm/get_auto_code_url').then(function (res) {
                //跳转微信第三方授权页面
                window.location.href = res.data
            })
        },
        //获取授权信息-保存调用令牌
        get_autoinfo() {
            this.requestApi('/adm/get_authorizer_info', {
                auth_code: this.auth_code
            }).then(function (res) {
                if (res.status) {
                    //返回原始跳转页
                    history.go(-3)
                }
            })
        },
        /**
         * 根据参数名 获取 URL 路径中的参数
         * @param {String} name 要读取的参数名称
         */
        getUrlParam (name) {
            var url = decodeURIComponent(window.location.href)
            var reg = new RegExp('(?:(?:&|\\?)' + name + '=([^&]*))|(?:/' + name + '/([^/]*))', 'i');
            var r = url.match(reg);
            if (r != null)
                return r[1] || r[2];
            return null;
        },
    }
}
</script>

<style scoped>

</style>